import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  background: #866d9e;
`;

const HeaderContainer = styled.div`
  padding: 0 0 12px 1.0875rem;
  max-width: 25%;
  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 8px 1.0875rem;
    max-width: 370px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0px 0 12px 1.0875rem;
    max-width: 40%;
  }
`;

const H1 = styled.h1`
  margin: 0;
  @media (max-width: 767px) {
    font-size: 28px;
    text-align: center;
  }
`;

const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 4px #300a56;
  font-family: sans-serif;
  font-size: 28px;
  @media (max-width: 767px) {
    font-size: 26px;
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 24px;
  }
`;

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <HeaderContainer>
      <H1>
        <HeaderLink to="/">{siteTitle}</HeaderLink>
      </H1>
    </HeaderContainer>
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
